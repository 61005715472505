<template>
    <div v-if="rows.length">
        <apexchart
            :height="160"
            type="line"
            :options="chart_options"
            :series="chart_data"
        />

        <h5>{{ rows[rows.length - 1].filed_at | nibnut.date("MMM dd") }}</h5>
        <data-table
            id="zendesk-latest-threats"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-head="false"
            :show-totals="false"
            :searchable="false"
            :clickable="false"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.subject }}</h6>
                <div class="text-small text-gray">{{ row.requested_by }}</div>
            </template>
                            filed_at: { label: "Date", sort: false },
                requested_by: { label: "Requester Name", sort: false },
                subject: { label: "Subject", sort: false },
                response_time: { label: "Response Time", sort: false },
                status: { label: "Status", sort: false, cell_class: "no-wrap" }

            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'filed_at'">
                    {{ row.filed_at | nibnut.date("MMM-dd") }}
                </span>
                <span v-else-if="field === 'subject'">
                    {{ row.subject }}
                </span>
                <span v-else-if="field === 'response_time'">
                    <span
                        v-if="row.resolve_time || row.response_time"
                    >
                        {{ row.resolve_time || row.response_time | nibnut.number("0,0") }} {{ $root.translate("min") }}
                    </span>
                </span>
                <span v-else-if="field === 'status'">
                    {{ standardized_status(row.status) }}
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"

import is_report from "./IsReport"

export default {
    name: "ZendeskReport",
    mixins: [is_report],
    methods: {
        standardized_status (status) {
            if(status) {
                if(status.match(/^closed$/i)) return this.$root.translate("Resolved")
            }
            return status
        }
    },
    computed: {
        state_identifier () {
            return "zendesk-report"
        },
        chart_raw_data () {
            const data = {}
            this.rows.forEach(row => {
                const date = parseISO(row.filed_at)
                const index = format(date, "yyyy-MM-dd")
                if(!data[index]) data[index] = { category: format(date, "MM-dd"), count: 0 }
                data[index].count++
            })

            const dates = Object.keys(data)
            dates.sort()
            const raw_data = {}
            dates.forEach(date => {
                raw_data[data[date].category] = data[date].count
            })

            return raw_data
        },
        chart_options () {
            const categories = Object.keys(this.chart_raw_data)

            return {
                chart: {
                    id: "line-zendesk",
                    height: 160,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories
                },
                yaxis: {
                    forceNiceScale: true,
                    labels: {
                        formatter: (value) => {
                            return this.$numeral(value).format("0,0")
                        }
                    }
                }
            }
        },
        chart_data () {
            return [
                {
                    name: this.$root.translate("Tickets"),
                    data: Object.values(this.chart_raw_data)
                }
            ]
        }
    },
    data () {
        return {
            columns: {
                filed_at: { label: "Date", sort: false, cell_class: "no-wrap" },
                requested_by: { label: "Requester Name", sort: false },
                subject: { label: "Subject", sort: false },
                response_time: { label: "Response Time", sort: false, cell_class: "no-wrap" },
                status: { label: "Status", sort: false, cell_class: "no-wrap" }
            },

            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "filed_at",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
